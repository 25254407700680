import './index.less'
import img1 from '../../assets/footer/img1.png'
import img2 from '../../assets/footer/img2.png'
import img3 from '../../assets/footer/img3.png'
import img4 from '../../assets/footer/img4.png'
import img_logo from '../../assets/footer/footer_logo.png'
// import qrcode1 from '../../assets/footer/1.png'
// import qrcode2 from '../../assets/footer/2.webp'
// import qrcode3 from '../../assets/footer/3.webp'
import qrcode3 from '../../assets/footer/tangGo公众号.jpg'

interface FooterProps {
  // 为true，在主页使用；false: 用户协议页面
  global?: boolean;
}

const Footer = ({global = true}: FooterProps) => {
  return <footer className={`${global ? 'home-footer' : ''}`}>
    <div className="company">
      <div className="logo">
        <a href="https://www.nosugartech.com/" target='_blank' rel="noreferrer">
          <img src={img_logo} alt="成都无糖信息技术有限公司"/>
        </a>
      </div>
      <div className="line"/>
      <div className="introduce">
        <div>
          <a href="https://www.nosugartech.com/#/companyIntro" target='_blank' rel="noreferrer">公司介绍</a>
          <a href="https://nosugar.yuque.com/org-wiki-mrhite/tanggo/cpvoci3mibk33l1u" target='_blank'rel="noreferrer">团队介绍</a>
          <a href="https://www.nosugartech.com/#/developHistory" target='_blank'rel="noreferrer">发展历程</a>
        </div>
        <div style={{marginTop: global ? '.07rem' : 0}}>
          <a href="https://www.nosugartech.com/#/contactUs" target='_blank'rel="noreferrer">联系我们</a>
          <a href="https://www.nosugartech.com/#joinUs" target='_blank'rel="noreferrer">加入我们</a>
          <a href="https://www.nosugartech.com/#/policeCooperation" target='_blank'rel="noreferrer">商务合作</a>
        </div>
      </div>
      <div className="line"/>
      <div className="other">
        <ul>
     {/*     <li>
            <img src={img1} alt="无糖学院"/>无糖学院
          </li>*/}
          <li>
            <img src={img2} alt="阿斯巴甜攻防实验室"/>阿斯巴甜攻防实验室
          </li>
          <li>
            <img src={img3} alt="PKAV"/>PKAV
          </li>
       {/*   <li>
            <img src={img4} alt="黑白呸"/>黑白呸
          </li>*/}
        </ul>
        <div className='copyright' style={{paddingTop: global ? '.07rem' : 0}}>NoSugarTech Copyright@2017
          蜀ICP备17042455号-1
        </div>
      </div>
    </div>
    <div className="qr-code">
   {/*   <div><img src={qrcode1} alt="无糖信息公众号"/></div>
      <div><img src={qrcode2} alt="黑白呸"/></div>
      <div><img src={qrcode3} alt="无糖反网络犯罪研究中心"/></div>*/}
      <div><img src={qrcode3} alt="tangGo公众号"/></div>
    </div>
  </footer>
}
export default Footer