import {Item} from '../PageThree';
import {Image} from '../PageFive';
import './index.less';
import WeChatGroup from "../WeChatGroup";
import React, {memo, useEffect, useState} from "react";
import CourseButton from "../CourseButton";

interface PageSevenProps {
  onDownloadClick?: () => void;
  onMoreClick?: () => void
}

function PageSeven(props: PageSevenProps) {
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    fetch('/official/thank').then(response => response.json()).then(res => {
      console.log(res, 333333333);
      if (res.code === 200) {
        setValue(res?.data?.text || '');
      }
    });
  }, []);

  return (
    <div className='PageSeven'>
      <div className='body'>
        <div className='platform'>
          <img src={require('../../assets/pageSeven/支持平台.png')} className='platform_inner'/>
          <CourseButton/>
        </div>
        <Image src={require('../../assets/pageSeven/整体.png')}/>
        <div className='content'>
          <Item
            style={{position: 'initial'}}
            defaultImage={require('../../assets/pageSeven/下载社区版-n.png')}
            className='btn'
            hoverImage={require('../../assets/pageSeven/下载社区版-h.png')}
            onClick={() => {
              props?.onDownloadClick?.();
            }}
          />
          <Item
            style={{position: 'initial'}}
            defaultImage={require('../../assets/pageSeven/更多版本介绍-n.png')}
            className='btn'
            hoverImage={require('../../assets/pageSeven/更多版本介绍-h.png')}
            onClick={() => {
              props?.onMoreClick?.();
            }}
          />
          <WeChatGroup>
            <Item
              style={{position: 'initial'}}
              defaultImage={require('../../assets/pageSeven/微信交流群-n.png')}
              className='btn'
              hoverImage={require('../../assets/pageSeven/微信交流群-h.png')}
            />
          </WeChatGroup>
        </div>
      </div>
      <div className='Thanks'>
        <Image src={require('../../assets/pageSeven/致谢.png')} style={{width: '2.175rem'}}/>
        <div className='Thanks_body'>
          {value}
        </div>
      </div>
      <div className='footer'>
        <Image src={require('../../assets/pageSeven/脚.png')}/>
        <div
          className='logo'
          onClick={() => {
            window.open("https://www.nosugartech.com/", '_blank');

          }}
        />
        <div className='link'>
          <div
            className='company'
            onClick={() => {
              window.open("https://www.nosugartech.com/#/companyIntro", '_blank');
            }}
          />
          <div className='team' onClick={() => {
            window.open("https://nosugar.yuque.com/org-wiki-mrhite/tanggo/cpvoci3mibk33l1u", '_blank');
          }}/>
          <div className='course' onClick={() => {
            window.open("https://www.nosugartech.com/#/developHistory", '_blank');
          }}/>
          <div className='relation' onClick={() => {
            window.open("https://www.nosugartech.com/#/contactUs", '_blank');
          }}/>
          <div className='join' onClick={() => {
            window.open("https://www.nosugartech.com/#/joinUs", '_blank');
          }}/>
          <div className='cooperation' onClick={() => {
            window.open("https://www.nosugartech.com/#/policeCooperation", '_blank');
          }}/>
        </div>
        <div className='QRCode'>
  {/*        <div className='QRCode_img'>
            <Image src={require('../../assets/footer/1.png')}/>
            <img className='magnify' src={require('../../assets/footer/1.png')} alt=""/>
          </div>
          <div className='QRCode_img'>
            <Image src={require('../../assets/footer/2.webp')}/>
            <img className='magnify' src={require('../../assets/footer/2.webp')} alt=""/>

          </div>
          <div className='QRCode_img'>
            <Image src={require('../../assets/footer/3.webp')}/>
            <img className='magnify' src={require('../../assets/footer/3.webp')} alt=""/>
          </div>*/}
          <div className='QRCode_img'>
            <Image src={require('../../assets/footer/tangGo公众号.jpg')}/>
            <img className='magnify' src={require('../../assets/footer/tangGo公众号.jpg')} alt="tangGo公众号"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(PageSeven);
