import React, {useEffect, useState} from 'react';
import './App.less';
import headerBg from './assets/header_bg.png';
import DownloadModal from "./components/PageOne/DownloadModal";
import {Link, Outlet, useLocation} from "react-router-dom";
import CourseButton from './components/CourseButton';

function App() {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementById('header');
      if (header) header.style.left = `-${window.scrollX}px`;
    };
    window.addEventListener('scroll', handleScroll);
    setTimeout(() => {
      // @ts-ignore
      document.querySelector('#root').style.setProperty('--backgroundSize', 'contain');
    }, 2000);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="App" data-autofit='{"minWidth": 1366px}'>
      <header className="App-header" id='header'>
        <img src={headerBg} alt="TangGo测试平台" className='headerImg'/>
        <div className="headerContent">
          <div className="menu">
            <div className={`menuItem ${location.pathname === '/' ? 'menuItemActive' : ''}`}>
              <Link to='/'>首页</Link>
            </div>
            <div className="lineV"/>
            <div className='menuItem'>
              <a href="https://nosugar.yuque.com/org-wiki-mrhite/tanggo/cpvoci3mibk33l1u" target='_blank' rel="noreferrer">关于我们</a>
            </div>
            <div className="lineV"/>
            {/*<div className={`menuItem ${location.pathname === '/feedback' ? 'menuItemActive' : ''}`}>*/}
            {/*  <Link to='/feedback'>意见反馈</Link>*/}
            {/*</div>*/}
            <div className='menuItem'>
              <a href="https://github.com/TangGolang/TangGo/issues" target='_blank' rel="noreferrer">意见反馈</a>
            </div>
          </div>
          <div className="headerRight">
            <div className="downloadBtn" onClick={() => setOpen(true)}/>
          </div>
          <div className='course_box'>
            <CourseButton/>
          </div>
        </div>
      </header>
      <div className="pageContent">
        <Outlet/>
      </div>
      <DownloadModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </div>
  );
}

export default App;
